import axios from 'axios';
import {FITI_FITI_API_URL} from "./URL";

const BlockAreaService = {
    getAllBlockedArea: () => {
        return axios.get(`${FITI_FITI_API_URL}/blocked-area/all`);
    },
    addBlockedArea: (data) => {
        return axios.post(`${FITI_FITI_API_URL}/blocked-area`, data);
    },
    deleteBlockedArea: (id) => {
        return axios.delete(`${FITI_FITI_API_URL}/blocked-area/${id}`,);
    },
};

export default BlockAreaService;
