import axios from 'axios';
import {FITI_FITI_API_URL} from "./URL";

const CityService = {
    city: () => {
        return axios.get(`${FITI_FITI_API_URL}/city`);
    },
    state: (cityId) =>  {
        return axios.get(`${FITI_FITI_API_URL}/city/${cityId}`,)
    }
};

export default CityService;
