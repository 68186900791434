import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/reset.css'
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const container = document.getElementById('root');
const root = createRoot(container);

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('tr');


root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

serviceWorker.unregister();
