import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ScooterService from "services/ScooterService";
import axios from "axios";


export const getScooterDetailGeneralInfo = createAsyncThunk('scooterTransaction/getScooterDetailGeneralInfo', async ({ order, page, take, id }, { rejectWithValue }) => {
    try {
        const response = await ScooterService.getScooterDetailGeneralInfo(order, page, take, id);
        if (response.status === 200) {
            return {
                transaction: response.data.data,
                totalTransaction: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            };
        } else {
            return rejectWithValue('Failed to getScooterDetailGeneralInfo');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const scooterTransactionSlice = createSlice({
    name: 'scooterTransaction',
    initialState: {
        totalTransaction: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null,
        transaction: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getScooterDetailGeneralInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getScooterDetailGeneralInfo.fulfilled, (state, action) => {
                state.transaction = action.payload.transaction;
                state.totalTransaction = action.payload.totalTransaction;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getScooterDetailGeneralInfo.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})

export default scooterTransactionSlice.reducer;