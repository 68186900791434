import axios from 'axios';
import { FITI_FITI_API_URL } from './URL';

const TicketService = {
    getAllTicket: (order = 'ASC', page = 1, take = 10, filter={}) => {
        return axios.get(`${FITI_FITI_API_URL}/ticket/all`, {
            params: {order, page, take, ...filter}
        });
    },
    addTicket: (data) => {
        return axios.post(`${FITI_FITI_API_URL}/ticket`, data);
    },
    finishTicket: (id) => {
        return axios.post(`${FITI_FITI_API_URL}/ticket/finish/${id}`);
    }
}

export default TicketService;