import axios from 'axios';
import { FITI_FITI_API_URL } from './URL';

const ScooterModelService = {
    getAllScooterModel: (order = 'ASC', page = 1, take = 10, filter={}) => {
        return axios.get(`${FITI_FITI_API_URL}/models/all`, {
            params: {order, page, take, ...filter}
        });
    },
    addScooterModel: (data) => {
        return axios.post(`${FITI_FITI_API_URL}/models`, data);
    },
    updateScooterModel: (id, data) => {
        return axios.patch(`${FITI_FITI_API_URL}/models/${id}`, data);
    },
    deleteScooterModel: (id) => {
        return axios.delete(`${FITI_FITI_API_URL}/models/${id}`);
    }
}

export default ScooterModelService;