import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ScooterModelService from "services/ScooterModelService";

export const getAllScooterModel = createAsyncThunk('models/getAllScooterModel', async ({ order, page, take, filter }, { rejectWithValue }) => {
    try {
        const response = await ScooterModelService.getAllScooterModel(order, page, take, filter);
        if (response.status === 200) {
            return {
                scooterModel: response.data.data,
                totalScooterModel: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            }
        } else {
            return rejectWithValue('Failed to fetch users');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const addScooterModel = createAsyncThunk('models/addScooterModel', async (data, { rejectWithValue }) => {
    try {
        const response = await ScooterModelService.addScooterModel(data);
        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return rejectWithValue('Failed to add user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
})

export const updateScooterModel = createAsyncThunk('models/updateScooterModel', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await ScooterModelService.updateScooterModel(id, data);
        if (response.status === 200 || response.status === 204) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to update scooter model');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const deleteScooterModel = createAsyncThunk('models/deleteScooterModel', async (id, { rejectWithValue }) => {
    try {
        const response = await ScooterModelService.deleteScooterModel(id);
        if (response.status === 200 || response.status === 204 || response.status === 201) {
            return id;
        } else {
            return rejectWithValue('Failed to delete scooter model');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const scooterModelSlice = createSlice({
    name: 'scooterModel',
    initialState: {
        scooterModel: [],
        totalScooterModel: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllScooterModel.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllScooterModel.fulfilled, (state, action) => {
                state.scooterModel = action.payload.scooterModel;
                state.totalScooterModel = action.payload.totalScooterModel;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getAllScooterModel.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(addScooterModel.pending, (state) => {
                state.loading = true;
            })
            .addCase(addScooterModel.fulfilled, (state, action) => {
                state.scooterModel.push(action.payload.data);
                state.totalScooterModel += 1;
                state.loading = false;
            })
            .addCase(addScooterModel.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateScooterModel.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateScooterModel.fulfilled, (state, action) => {
                const index = state.scooterModel.findIndex(model => model.id === action.payload.id);
                if (index !== -1) {
                    state.scooterModel[index] = { ...state.scooterModel[index], ...action.payload };
                }
                state.loading = false;
            })
            .addCase(updateScooterModel.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(deleteScooterModel.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteScooterModel.fulfilled, (state, action) => {
                state.scooterModel = state.scooterModel.filter(model => model.id !== action.payload)
                state.totalScooterModel -= 1
                state.loading = false
            })
            .addCase(deleteScooterModel.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})

export default scooterModelSlice.reducer;