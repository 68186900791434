import axios from 'axios';
import { FITI_FITI_API_URL } from './URL';
import { forceDeleteUser } from 'store/slices/userSlice';

const UserService = {
    getAllUsers: (order = 'ASC', page = 1, take = 10, filter = {}) => {
        return axios.get(`${FITI_FITI_API_URL}/users/all`, {
            params: { order, page, take, ...filter }
        });
    },
    addUser: (data) => {
        return axios.post(`${FITI_FITI_API_URL}/users`, data);
    },
    updateUser: (id, data) => {
        return axios.patch(`${FITI_FITI_API_URL}/users/${id}`, data);
    },

    recoveryUser: (id,) => {
        return axios.patch(`${FITI_FITI_API_URL}/users/recovery/${id}`);
    },
    deleteUser: (id) => {
        return axios.delete(`${FITI_FITI_API_URL}/users/${id}`);
    },
    forceDeleteUser: (id) => {
        return axios.delete(`${FITI_FITI_API_URL}/users/force/${id}`);
    },
    getAllUserSummary: () => {
        return axios.get(`${FITI_FITI_API_URL}/users/all/summary`);
    },
};

export default UserService;
