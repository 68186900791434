import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CityService from 'services/CityService';

export const fetchCities = createAsyncThunk('city/fetchCities', async (_, { rejectWithValue }) => {
    try {
        const response = await CityService.city();
        if (response.status === 200) {
            return response.data.data;
        } else {
            return rejectWithValue('Cities fetching failed');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const fetchStates = createAsyncThunk('city/fetchStates', async (cityId, { rejectWithValue }) => {
    try {
        const response = await CityService.state(cityId);
        if (response.status === 200) {
            return { cityId, states: response.data.data };
        } else {
            return rejectWithValue('States fetching failed');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const citySlice = createSlice({
    name: 'city',
    initialState: {
        cities: [],
        states: {},
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCities.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCities.fulfilled, (state, action) => {
                state.cities = action.payload;
                state.loading = false;
            })
            .addCase(fetchCities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchStates.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchStates.fulfilled, (state, action) => {
                const { cityId, states } = action.payload;
                state.states[cityId] = states;
                state.loading = false;
            })
            .addCase(fetchStates.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default citySlice.reducer;
