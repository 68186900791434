import axios from 'axios';
import { FITI_FITI_API_URL } from './URL';

const NotificationService = {
    getAllNotification: (order = 'ASC', page = 1, take = 10) => {
        return axios.get(`${FITI_FITI_API_URL}/notification`, {
            params: {order, page, take}
        });
    },
    addNotification: (data) => {
        return axios.post(`${FITI_FITI_API_URL}/notification`, data);
    },
}

export default NotificationService;