import antdEnUS from 'antd/es/locale/en_US';
import antdTrTR from "antd/es/locale/tr_TR";
import en from './locales/en_US.json'
import tr from './locales/tr_TR.json'
import enErrors from './locales/error-messages/en_US_ERR.json';
import trErrors from './locales/error-messages/tr_TR_ERR.json';
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { THEME_CONFIG } from 'configs/AppConfig';

export const resources = {
    en: {
        translation: {
            ...en,
            ...enErrors
        },
        antd: antdEnUS
    },
    tr: {
        translation: {
            ...tr,
            ...trErrors
        },
        antd: antdTrTR
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: THEME_CONFIG.locale,
    lng: THEME_CONFIG.locale,
    interpolation: {
        escapeValue: true,
        format: (value, format, lng) => {
            if (format === 'number') return new Intl.NumberFormat(lng).format(value);
            return value;
        }
    }
})

export default i18n;