import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import map from './slices/mapSlice';
import city from './slices/citySlice';
import users from './slices/userSlice';
import scooters from './slices/scooterSlice';
import scooterModel from './slices/scooterModelSlice';
import notifications from './slices/notificationSlice';
import stores from './slices/storeSlice';
import adminUsers from './slices/adminUserSlice';
import faults from './slices/faultSlice';
import scooterTransaction from './slices/scooterTransaction';
import reports from './slices/reportSlice';
import ticket from './slices/ticketSlice';

const rootReducer = (asyncReducers = {}) => combineReducers({
    theme,
    auth,
    map,
    city,
    users,
    scooters,
    scooterModel,
    notifications,
    stores,
    adminUsers,
    faults,
    scooterTransaction,
    reports,
    ticket,
    ...asyncReducers,
});

export default rootReducer;
