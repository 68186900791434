import io from 'socket.io-client';
import AuthService from "./AuthService";
import {tokenExpired} from "helpers/AuthHelpers";

const WebSocketService = (() => {
    let socket;

    const connect = async () => {
        let token = localStorage.getItem('AUTH_TOKEN');

        if (!token || tokenExpired(token)) {
            try {
                const response = await AuthService.refreshToken();
                token = response.data.data.token;
                localStorage.setItem('AUTH_TOKEN', token);
            } catch (error) {
                console.error('Failed to refresh token:', error);
                return;
            }
        }

        socket = io('wss://backendff.fitifitiscooter.com/gateway', {
            extraHeaders: {
                Authorization: `Bearer ${token}`
            }
        });

        socket.on('connect', () => {
            console.warn('Connected to WebSocket server');
        });

        socket.on('disconnect', () => {
            console.warn('Disconnected from WebSocket server');
        });

        socket.on('connect_error', (error) => {
            console.error('WebSocket connection error:', error);
        });

        socket.on('error', (error) => {
            console.error('WebSocket error:', error);
            disconnect();
        });
    };

    const onScooters = (callback) => {
        if (!socket) return;
        socket.on('scooters', (msg) => {
            callback(msg);
        });
    };

    const disconnect = () => {
        if (socket) {
            socket.off('scooters');
            socket.disconnect();
            socket = null;
        }
    };

    return {
        connect,
        onScooters,
        disconnect,
    };
})();

export default WebSocketService;
