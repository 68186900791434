import axios from 'axios';
import {FITI_FITI_API_URL} from "./URL";

const ReportService = {
    general: () => {
        return axios.get(`${FITI_FITI_API_URL}/reports/general`);
    },
    userReport: (userId, startDate, endDate) => {
        return axios.get(`${FITI_FITI_API_URL}/reports/user/${userId}`, {
            params: { end: endDate, start: startDate }
        });
    },
    scooterReports: (scooterId, startDate, endDate) => {
        return axios.get(`${FITI_FITI_API_URL}/reports/scooter/${scooterId}`, {
            params: { end: endDate, start: startDate }
        });
    }
};

export default ReportService;
