import axios from 'axios';
import {FITI_FITI_API_URL} from "./URL";
import store from 'store';
import {authenticated, refreshToken, signOutSuccess} from "../store/slices/authSlice";
import {showNotification} from "../store/slices/notificationSlice";
import i18n from "i18next";

const AuthService = {
	login: (data) => {
		return axios.post(`${FITI_FITI_API_URL}/auth/login`, data);
	},
	logout: () => {
		const token = localStorage.getItem('AUTH_TOKEN');
		return axios.post(`${FITI_FITI_API_URL}/auth/logout`, {}, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
	},
	changePassword: (data) => {
		//const token = localStorage.getItem('AUTH_TOKEN');
		return axios.patch(`${FITI_FITI_API_URL}/users/password`, data)
	},
	refreshToken: () => {
		const refreshToken = localStorage.getItem('REFRESH_TOKEN');

		return axios.post(`${FITI_FITI_API_URL}/auth/refresh-token`, { refreshToken });
	},
	updateProfile: (data) => {
		return axios.patch(`${FITI_FITI_API_URL}/users`, data);
	}
};

export const setupInterceptors = (navigate) => {
	axios.interceptors.request.use(
		config => {
			const token = localStorage.getItem('AUTH_TOKEN');
			if (token) {
				config.headers['Authorization'] = `Bearer ${token}`;
			}
			return config;
		},
		error => {
			Promise.reject(error);
		});

	axios.interceptors.response.use(
		response => response,
		async error => {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;

				const result = await store.dispatch(refreshToken());
				const newToken = result.payload;

				if (newToken) {
					localStorage.setItem('AUTH_TOKEN', newToken);
					axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

					originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
					return axios(originalRequest);
				} else {
					localStorage.removeItem('AUTH_TOKEN');
					localStorage.removeItem('REFRESH_TOKEN');
					localStorage.removeItem('USER_INFO');
					window.location.reload();
					navigate('/login');
				}

			} else if (error.response && error.response.data && error.response.data.message) {
				let errorMessage = error.response.data.message;

				if (errorMessage.includes('|')) {
					const [errorCode, value] = errorMessage.split('|');

					errorMessage = i18n.t(errorCode, {
						defaultValue: errorCode,
						value: value
					});
				} else {
					errorMessage = i18n.t(errorMessage, {
						defaultValue: errorMessage
					});
				}

				store.dispatch(showNotification({
					message: 'Hata',
					description: errorMessage,
					type: 'error',
					key: 'unique_key_error'
				}));
			}
			return Promise.reject(error);
		}
	);
};

export default AuthService;
