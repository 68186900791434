import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ReportService from "services/ReportService";

export const getReportsGeneral = createAsyncThunk('reports/getReportsGeneral', async (_, { rejectWithValue }) => {
    try {
        const response = await ReportService.general();
        if (response.status === 200) {
            return {
                weeklyUserCount: response.data.data.weeklyUserCount,
                monthlyUserCount: response.data.data.monthlyUserCount,
                lastThreeMonthUserActivity: response.data.data.lastThreeMonthUserAcitivity,
                lastThreeMonthScooterDistance: response.data.data.lastThreeMonthScooterDistance,
            };
        } else {
            return rejectWithValue('Cities fetching failed');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const userReport = createAsyncThunk('reports/userReport', async ({userId, startDate, endDate}, { rejectWithValue }) => {
    try {
        const response = await ReportService.userReport(userId, startDate, endDate);
        if (response.status === 200) {
            return {
                userReports: response.data.data,
            };
        } else {
            return rejectWithValue('userReport');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const scooterReport = createAsyncThunk('reports/scooterReport', async ({scooterId, startDate, endDate}, { rejectWithValue }) => {
    try {
        const response = await ReportService.scooterReports(scooterId, startDate, endDate);
        if (response.status === 200) {
            return {
                scooterReports: response.data.data,
            };
        } else {
            return rejectWithValue('scooterReports');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const reportSlice = createSlice({
    name: 'reports',
    initialState: {
        loading: false,
        error: null,
        weeklyUserCount: null,
        monthlyUserCount: null,
        lastThreeMonthUserActivity: null,
        lastThreeMonthScooterDistance: null,
        userReports: {},
        scooterReports: {}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReportsGeneral.pending, (state) => {
                state.loading = true;
            })
            .addCase(getReportsGeneral.fulfilled, (state, action) => {
                state.weeklyUserCount = action.payload.weeklyUserCount;
                state.monthlyUserCount = action.payload.monthlyUserCount;
                state.lastThreeMonthUserActivity = action.payload.lastThreeMonthUserActivity;
                state.lastThreeMonthScooterDistance = action.payload.lastThreeMonthScooterDistance;
                state.loading = false;
            })
            .addCase(getReportsGeneral.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(userReport.pending, (state) => {
                state.loading = true;
            })
            .addCase(userReport.fulfilled, (state, action) => {
                state.userReports = action.payload.userReports;
                state.loading = false;
            })
            .addCase(userReport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(scooterReport.pending, (state) => {
                state.loading = true;
            })
            .addCase(scooterReport.fulfilled, (state, action) => {
                state.scooterReports = action.payload.scooterReports;
                state.loading = false;
            })
            .addCase(scooterReport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default reportSlice.reducer;
