import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import StoreService from "services/StoreService";

export const getAllStores = createAsyncThunk(
    'stores/getAllStores',
    async (_, { rejectWithValue }) => {
    try {
        const response = await StoreService.getAllStores();
        if (response.status === 200) {
            return {
                stores: response.data.data,
            }
        } else {
            return rejectWithValue('Failed to fetch users');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const storeSlice = createSlice({
    name: 'stores',
    initialState: {
        stores: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllStores.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllStores.fulfilled, (state, action) => {
                state.stores = action.payload.stores;
                state.loading = false;
            })
            .addCase(getAllStores.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})

export default storeSlice.reducer;