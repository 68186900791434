import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TicketService from "services/TicketService";

export const getAllTicket = createAsyncThunk('models/getAllTicket', async ({ order, page, take, filter }, { rejectWithValue }) => {
    try {
        const response = await TicketService.getAllTicket(order, page, take, filter);
        if (response.status === 200) {
            return {
                tickets: response.data.data,
                totalTickets: response.data.meta.itemCount,
                page: response.data.meta.page,
                take: response.data.meta.take,
            }
        } else {
            return rejectWithValue('Failed to fetch users');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const addTicket = createAsyncThunk('models/addTicket', async (data, { rejectWithValue }) => {
    try {
        const response = await TicketService.addTicket(data);
        if (response.status === 200 || response.status === 201) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to add user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});

export const finishTicket = createAsyncThunk('models/finishTicket', async (id, { rejectWithValue }) => {
    try {
        const response = await TicketService.finishTicket(id);
        if (response.status === 200 || response.status === 201) {
            return response.data.data;
        } else {
            return rejectWithValue('Failed to add user');
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Network Error');
    }
});


export const ticketSlice = createSlice({
    name: 'ticket',
    initialState: {
        tickets: [],
        totalTickets: 0,
        page: 1,
        take: 10,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllTicket.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllTicket.fulfilled, (state, action) => {
                state.tickets = action.payload.tickets;
                state.totalTickets = action.payload.totalTickets;
                state.page = action.payload.page;
                state.take = action.payload.take;
                state.loading = false;
            })
            .addCase(getAllTicket.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(addTicket.pending, (state) => {
                state.loading = true;
            })
            .addCase(addTicket.fulfilled, (state, action) => {
                state.tickets.push(action.payload.data);
                state.totalTickets += 1;
                state.loading = false;
            })
            .addCase(addTicket.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(finishTicket.pending, (state) => {
                state.loading = true;
            })
            .addCase(finishTicket.fulfilled, (state, action) => {
                state.tickets = state.tickets.filter(ticket => ticket.id !== action.payload);
                state.loading = false;
            })
            .addCase(finishTicket.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
})

export default ticketSlice.reducer;